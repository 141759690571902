(function () {
    'use strict';

    angular
        .module('app')

        /* @ngInject */
        .constant('ORIGINAL_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<original-form-admin' +
                ' item="' + resolve + 'item"' +
                ' previous-params="' + resolve + 'previousParams"' +
                ' agentes="' + resolve + 'agentes"' +
                ' generos="' + resolve + 'generos"></original-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (Original) {
                        return new Original();
                    },
                    /* @ngInject */
                    agentes: function (Agente) {
                        return new Agente.findAll({sortProperty: 'nombre'}).$promise.then(function (result) {
                            return result.content;
                        });
                    },
                    /* @ngInject */
                    generos: function (EnumService) {
                        return EnumService.get("genero-original");
                    }
                }
            }
        });
})();
