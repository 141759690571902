(function () {
    'use strict';

    angular.module('app')
        .factory('Traduccion', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = "traduccion";
        return ResourceHelper.getResource(entidad, {}, {
            getFicha: {
                method: 'GET',
                url: '/api/public/' + entidad + '/getFicha'
            },
            buscar: {
                method: 'POST',
                url: '/api/public/' + entidad + '/buscar'
            }
        });
    }

})();
