(function () {
    'use strict';

    angular
        .module('app')
        .component('traduccionFormAdmin', {
            templateUrl: 'app/admin/traduccion/traduccion-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<',
                idiomas: '<',
                ciudades: '<',
                roles: '<'
            }
        });

    /* @ngInject */
    function Controller($timeout, $state, $translate, ModalService, AlertService,
                        Agente, Original, Organismo, Ciudad, RutasService, TraduccionHelper,
                        CIUDAD_FORM_ADMIN, AGENTE_FORM_ADMIN, ORIGINAL_FORM_ADMIN, ORGANISMO_FORM_ADMIN) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0) input').focus();
        });

        vm.urlBasePdf = RutasService.getTraduccionPdfUrl(vm.item.id);

        vm.tienePartes = TraduccionHelper.tienePartes(vm.item);

        // Inicializamos la información necesaria
        function init() {
            if (vm.item.ciudad) {
                vm.item.ciudades = [vm.item.ciudad];
            }

            // Separamos los organismos en tres entidades. No se permiten varios organismos por tipo.
            if (vm.item.organismos) {
                vm.item.organismos.forEach(function (organismo) {
                    switch (organismo.tipo) {
                        case 'EDI':
                            vm.editorial = organismo;
                            break;
                        case 'IMP':
                            vm.imprenta = organismo;
                            break;
                        case 'PUB':
                            vm.publicacion = organismo;
                            break;
                    }
                });
            }

            if (!vm.item.partes || !vm.item.partes.length > 1) {
                if (!vm.tienePartes) {
                    vm.item.partes = [];
                    // vm.tienePartes = true;
                    vm.parteOriginal = {};
                    vm.item.partes.push(vm.parteOriginal);
                }
            } else if (!vm.tienePartes) {
                if (vm.item.partes.length === 1) {
                    vm.parteOriginal = vm.item.partes[0];
                } else {
                    vm.parteOriginal = {};
                    vm.item.partes.push(vm.parteOriginal);
                }
            }
        }

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        // Guardar formulario
        vm.save = function () {
            vm.item.ciudad = vm.item.ciudades[0];

            // Metemos los organismos en una misma lista
            vm.item.organismos = [];
            if (vm.editorial && vm.editorial.id) {
                vm.item.organismos.push(vm.editorial);
            }
            if (vm.imprenta && vm.imprenta.id) {
                vm.item.organismos.push(vm.imprenta);
            }
            if (vm.publicacion && vm.publicacion.id) {
                vm.item.organismos.push(vm.publicacion);
            }

            // Validamos que al menos hay algún organismo o algún autor
            var tieneOrganismos = vm.item.organismos.length > 0;
            var tieneAgentes = vm.item.agentes.length > 0 || (vm.parteOriginal && vm.parteOriginal.agentes.length);
            if (!tieneAgentes) {
                tieneAgentes = vm.item.partes.some(function (parte) {
                    // if (parte.agentes.length) tieneAgentes = true;
                    return parte.agentes.length > 0;
                });
            }
            if (!tieneOrganismos && !tieneAgentes) {
                AlertService.warning($translate.instant("admin.traduccion.error.datosminimos"));
                angular.element("html,body").animate({scrollTop: 0}, "slow");
                return;
            }

            // FIXME: Apaño temporal para la edición de fechas...
            if (angular.isNumber(vm.item.fechaRevisionUrl)) {
                vm.item.fechaRevisionUrl = vm.item.fechaRevisionUrl / 1000;
            }

            vm.item.$save().then(function () {
                if (!vm.previousParams) {
                    vm.previousParams = {};
                }
                vm.previousParams.tipo = vm.item.tipo;
                ModalService.closeComponent(vm.item, 'admin/traduccion/list', vm.previousParams);
            });
        };

        vm.openCiudadModal = function () {
            ModalService.openComponent('admin.ciudad.create.title', CIUDAD_FORM_ADMIN, {size: 'lg'}).result.then(function (result) {
                // Añadimos el nuevo elemento al select
                vm.ciudades.push(result);
                // Asociamos el nuevo elemento a la entidad
                vm.item.ciudades.push(result);
            });
        };

        vm.openAgenteModal = function (traduccionAgente) {
            ModalService.openComponent('admin.agente.create.title', AGENTE_FORM_ADMIN, {size: 'lg'}).result.then(function (result) {
                // Asociamos el nuevo elemento a la entidad
                traduccionAgente.agente = result;
            });
        };

        vm.openOriginalModal = function (element) {
            ModalService.openComponent('admin.original.create.title', ORIGINAL_FORM_ADMIN, {size: 'lg'}).result.then(function (result) {
                // Asociamos el nuevo elemento a la entidad
                element.original = result;
            });
        };

        vm.openOrganismoModal = function (tipo) {
            ModalService.openComponent('admin.organismo.create.title', ORGANISMO_FORM_ADMIN, {size: 'lg'}).result.then(function (result) {
                // Asociamos el nuevo elemento a la entidad
                // Sólo si el tipo del organismo creado coincide con el que se está sustituyendo.
                if (result.tipo === tipo) {
                    switch (tipo) {
                        case 'EDI':
                            vm.editorial = result;
                            break;
                        case 'IMP':
                            vm.imprenta = result;
                            break;
                        case 'PUB':
                            vm.publicacion = result;
                            break;
                    }
                }

                // Recargamos la lista de ciudades por si acaso se ha añadido alguna nueva desde el formulario de organismos
                if (result.ciudades) {
                    Ciudad.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
                        vm.ciudades = data.content;
                    });
                }
            });
        };

        init();

        vm.agenteService = Agente;
        vm.originalService = Original;
        vm.organismoService = Organismo;
    }

})();
