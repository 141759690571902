(function () {
    'use strict';

    angular
        .module('app')
        .component('organismoDetails', {
            templateUrl: 'app/public/organismo/organismo-details.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                organismo: '<'
            }
        });

    /* @ngInject */
    function Controller() {
    }

})();
