(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:floatButtonLink
     * @description
     * Componente que incluye un botón flotante visible sólo cuando se cumplen los roles indicados y que permite acceder directamente a un estado que se le indique.
     *
     * Está pensado para acceder directamente a la edición de las entidades, en caso de estar en una ficha pública.
     *
     * @param {String} estado Estado al que se va a dirigir. Tiene que ser un string que incluya los parámetros necesarios concatenados.
     * Ejemplo: `'admin/item/edit({id:' + ctrl.item.id + '})'`
     * @param {String} [roles='ROLE_ADMIN'] Lista de roles a los que se le mostrará el botón.
     *
     * @requires app.directive:hasAnyAuthority
     *
     */
    angular
        .module('app')
        .component('floatButtonLink', {
            template: '<a class="btn-floating" ui-sref="{{ctrl.estado}}" title="{{\'goToAdmin.title\' | translate}}" ' +
            'has-any-authority="{{ctrl.roles}}"><i class="fa fa-pencil"></i></a>',
            controllerAs: 'ctrl',
            controller: function () {
                var vm = this;
                vm.roles = vm.roles || 'ROLE_ADMIN';
            },
            bindings: {
                estado: '<',
                roles: '@'
            }
        });

})();
