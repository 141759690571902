(function () {
    'use strict';

    // tipo: Tipo activo
    // tipos: Lista de tipos de traducción

    angular
        .module('app')
        .component('traduccionListAdmin', {
            templateUrl: 'app/admin/traduccion/traduccion-list.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                tipo: '<',
                tipos: '<'
            }
        });

    /* @ngInject */
    function Controller($stateParams, NG_TABLE_DEFAULT_PARAMS, NgTableParams, NgTableHelper,
                        ModalService, Descargas, Traduccion) {
        var vm = this;

        vm.item = Traduccion;
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.filter = {
            key: undefined,
            tipo: vm.tipo
        };
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fechaAno: 'asc'}
        }, NgTableHelper.settings(vm));

        // Eliminar
        vm.showRemoveConfirmation = function (id) {
            ModalService.open({
                    templateUrl: 'app/components/form/delete/entity.delete.modal.html',
                    controller: 'EntityDeleteModalController',
                    controllerAs: 'ctrl',
                    resolve: {
                        /* @ngInject */
                        item: function (Traduccion) {
                            return Traduccion.get({id: id}).$promise;
                        },
                        params: function () {
                            return {
                                title: 'admin.traduccion.delete.title',
                                body: 'admin.traduccion.delete.confirm',
                                property: 'titulo'
                            };
                        }
                    }
                }
            ).result.then(function () {
                vm.tableParams.reload();
            });
        };

        // Generar Excel
        vm.generarExcel = function () {
            Descargas.descargar('api/admin/traduccion/generarExcel', {tipo: vm.tipo});
        };
    }

})();
