(function () {
    'use strict';

    angular
        .module('app')
        .component('agenteDetails', {
            templateUrl: 'app/public/agente/agente-details.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                agenteDto: '<'
            }
        });

    /* @ngInject */
    function Controller(DateUtilService) {
        var vm = this;

        vm.agente = vm.agenteDto.agente;

        vm.nacimiento = DateUtilService.getDateFromInteger(vm.agente.nacimientoDia, vm.agente.nacimientoMes, vm.agente.nacimientoAno);
        vm.muerte = DateUtilService.getDateFromInteger(vm.agente.muerteDia, vm.agente.muerteMes, vm.agente.muerteAno);

    }

})();
