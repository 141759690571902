(function () {
    'use strict';

    angular
        .module('app')
        .component('originalFormAdmin', {
            templateUrl: 'app/admin/original/original-form.admin.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                item: '<',
                previousParams: '<',
                generos: '<'
            }
        });

    /* @ngInject */
    function Controller($timeout, $state, ModalService, Agente, AGENTE_FORM_ADMIN) {
        var vm = this;

        $timeout(function () {
            angular.element('.form-group:eq(0) input').focus();
        });

        vm.mode = $state.current.data.mode;
        vm.canSave = vm.mode === 'create' || vm.mode === 'edit';

        // Guardar formulario
        vm.save = function () {
            vm.item.$save().then(function () {
                ModalService.closeComponent(vm.item, 'admin/original/list', vm.previousParams);
            });
        };

        vm.openAgenteModal = function (traduccionAgente) {
            ModalService.openComponent('admin.agente.create.title', AGENTE_FORM_ADMIN, {size: 'lg'}).result.then(function (result) {
                // Asociamos el nuevo elemento a la entidad
                traduccionAgente.agente = result;
            });
        };

        vm.agenteService = Agente;
    }

})();
