(function () {
    'use strict';

    /**
     * Se muestra en un listado el nombre de los agentes (con un link a su ficha) y sus roles
     */

    angular
        .module('app')
        .component('agenteList', {
            templateUrl: 'app/public/agente/agente-list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                agentes: '<'
            }
        });

    /* @ngInject */
    function Controller() {
    }

})();
