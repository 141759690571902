(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('organismo', {
            parent: 'public',
            url: '/organismo/:id',
            data: {
                pageTitle: 'organismo.title'
            },
            views: {
                'content@': {
                    template: '<organismo-details organismo="$resolve.organismo"></organismo-details>'
                }
            },
            resolve: {
                /* @ngInject */
                organismo: function ($stateParams, Organismo) {
                    return Organismo.getFicha({id: $stateParams.id}).$promise;
                }
            }
        });
    }
})();
