(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('buscador', {
            parent: 'public',
            // Los nuevos parámetros se definen aquí, en el html, y en la opción de limpiar formulario.
            url: '/' +
            // Traduccion
            '?traduccionTitulo?traduccionIdioma?traduccionOrganismo?traduccionAnoInicio?traduccionAnoFin' +
            // Traductor
            '?traductorNombre?traductorGenero?traductorAutoriaOculta' +
            // Original
            '?originalTitulo?originalAutor?originalGenero?originalAutoriaOculta',
            data: {
                pageTitle: 'buscador.title'
            },
            views: {
                'content@': {
                    template: '<buscador idiomas="$resolve.idiomas" generos="$resolve.generos" tipos="$resolve.tipos"></buscador>'
                }
            },
            resolve: {
                /* @ngInject */
                idiomas: function (EnumService) {
                    return EnumService.get("idioma");
                },
                /* @ngInject */
                generos: function (EnumService) {
                    return EnumService.get("genero-agente");
                },
                /* @ngInject */
                tipos: function (EnumService) {
                    return EnumService.get("tipo-traduccion");
                }
            }
        });
    }
})();
