(function () {
    'use strict';

    angular
        .module('app')
        .component('traduccionDetails', {
            templateUrl: 'app/public/traduccion/traduccion-details.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                traduccionDto: '<'
            }
        });

    /* @ngInject */
    function Controller($translate, RutasService, DateUtilService, TraduccionHelper, Language) {
        var vm = this;

        vm.traduccion = vm.traduccionDto.traduccion;

        vm.verPartes = true;
        vm.tienePartes = TraduccionHelper.tienePartes(vm.traduccion);

        vm.urlBasePdf = RutasService.getTraduccionPdfUrl(vm.traduccion.id);

        // Organismos
        // Separamos los organismos en tres entidades. No se permiten varios organismos por tipo.
        if (vm.traduccion.organismos) {
            vm.traduccion.organismos.forEach(function (organismo) {
                switch (organismo.tipo) {
                    case 'EDI':
                        vm.editorial = organismo;
                        break;
                    case 'IMP':
                        vm.imprenta = organismo;
                        break;
                    case 'PUB':
                        vm.publicacion = organismo;
                        break;
                }
            });
        }

        // Datos bibliográficos
        vm.datos = [];
        if (vm.traduccion.coleccion) vm.datos.push(vm.traduccion.coleccion);
        if (vm.traduccion.ciudad) vm.datos.push(vm.traduccion.ciudad.nombreEn);
        if (vm.traduccion.fechaAno) {
            var fecha = DateUtilService.getDateFromInteger(vm.traduccion.fechaDia, vm.traduccion.fechaMes, vm.traduccion.fechaAno);
            vm.datos.push(fecha);
        }

        $translate.use(Language.getCurrent()).then(function () {
            if (vm.traduccion.volumen) vm.datos.push($translate.instant('traduccion.vol') + ' ' + vm.traduccion.volumen);
            if (vm.traduccion.numero) vm.datos.push($translate.instant('traduccion.num') + ' ' + vm.traduccion.numero);
            if (vm.traduccion.paginas) {
                vm.datos.push($translate.instant('traduccion.paginas') + ' ' + vm.traduccion.paginas);
            }
        });

        if (vm.traduccion.tipo === 'LIB' || vm.traduccion.tipo === 'PRE') {
            vm.parteUnica = vm.traduccionDto.partes[0];
        }

    }

})();
