(function () {
    'use strict';

    angular
        .module('app')

        /* @ngInject */
        .constant('ORGANISMO_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<organismo-form-admin' +
                ' item="' + resolve + 'item"' +
                ' previous-params="' + resolve + 'previousParams"' +
                ' tipos="' + resolve + 'tipos"' +
                ' ciudades="' + resolve + 'ciudades"></organismo-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (Organismo) {
                        return new Organismo();
                    },
                    /* @ngInject */
                    tipos: function (EnumService) {
                        return EnumService.get("tipo-organismo");
                    },
                    /* @ngInject */
                    ciudades: function (Ciudad) {
                        return Ciudad.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            }
        });
})();
