(function () {
    'use strict';

    angular
        .module('app')
        .component('organismoLink', {
            template: '<span ng-if="ctrl.organismo"><a ui-sref="organismo({id: ctrl.organismo.id})">{{ctrl.organismo.nombre}}</a>' +
            ' (<enumerado base="enum.tipo-organismo" value="{{ctrl.organismo.tipo}}"></enumerado>)</span>',
            controllerAs: 'ctrl',
            bindings: {
                organismo: '<'
            }
        });

})();
