(function () {
    'use strict';

    angular
        .module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, STATE_HELPER, TRADUCCION_FORM_ADMIN) {
        var config = TRADUCCION_FORM_ADMIN();
        var base = "admin/traduccion";
        var params = {
            base: base,
            baseUrl: "/" + base,
            translateBase: "admin.traduccion.",
            templateList: '<traduccion-list-admin tipo="$resolve.tipo" tipos="$resolve.tipos"></traduccion-list-admin>',
            templateForm: config.component
        };

        var state = STATE_HELPER(params);
        // Estado Padre
        angular.merge(state.parent, {
            data: {
                authorities: ['ROLE_ADMIN']
            }
        });
        // Listar
        angular.merge(state.list, {
            url: '/listar/:tipo/:page',
            params: {
                tipo: 'LIB'
            },
            resolve: {
                /* @ngInject */
                tipo: function ($stateParams) {
                    return $stateParams.tipo;
                },
                /* @ngInject */
                tipos: function (EnumService) {
                    return EnumService.get("tipo-traduccion");
                }
            }
        });
        // Crear
        angular.merge(state.create, {
            url: '/crear/:tipo',
            resolve: angular.merge(config.resolve, {
                /* @ngInject */
                item: function ($stateParams, Traduccion) {
                    return new Traduccion({tipo: $stateParams.tipo});
                }
            })
        });
        // Editar
        angular.merge(state.edit, {
            url: '/:id/editar/:tipo',
            resolve: angular.merge(config.resolve, {
                /* @ngInject */
                item: function ($stateParams, Traduccion) {
                    return Traduccion.get({id: $stateParams.id}).$promise;
                }
            })
        });

        // Se definen los estados
        $stateProvider
            .state(params.base, state.parent)
            .state(state.list.name, state.list)
            .state(state.create.name, state.create)
            .state(state.edit.name, state.edit);
    }
})();
