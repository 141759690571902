(function () {
    'use strict';

    angular.module('app')
        .factory('TraduccionHelper', Service);

    /* @ngInject */
    function Service() {
        return {
            tienePartes: tienePartes
        };

        function tienePartes(traduccion) {
            return traduccion.tipo === 'ANT' || traduccion.tipo === 'MUS';
        }

    }
})();
