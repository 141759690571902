(function () {
    'use strict';

    /**
     * Bloque añadido en las participaciones de agentes y organismos, indicando el nombre del original y sus autores.
     */
    angular
        .module('app')
        .component('participaEnOriginal', {
            templateUrl: 'app/public/helper/participa-en.original.html',
            // controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                traduccion: '<'
            }
        });

})();
