(function () {
    'use strict';

    angular.module('app')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider) {
        $stateProvider.state('public', {
            abstract: true,
            parent: 'site',
            views: {
                'header@': {
                    templateUrl: 'app/layouts/header/header.html',
                    controller: "HeaderController",
                    controllerAs: 'ctrl'
                },
                'navbar@': {
                    /* @ngInject */
                    controller: function () {
                    },
                    controllerAs: '$ctrl'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html'
                }
            },
            resolve: {
                /* @ngInject */
                isAdmin: function ($rootScope) {
                    $rootScope.isAdmin = false;
                },
                /* @ngInject */
                changeLang: function ($rootScope) {
                    $rootScope.changeLanguage("en")
                }
            }
        });
    }
})();
