(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:tesauro
     * @description
     * Componente que incluye un campo autocompletable.
     *
     * __Nota:__ `restricted` debería ser siempre true, ya que se desaconseja crear tesauros de manera dinámica en base de datos.
     * También se aconseja poner a true el campo `eliminable`.
     *
     * Referencia: {@link https://angular-ui.github.io/bootstrap/#typeahead}
     *
     * @param {Object} ngModel Objeto en el que se almacenará el resultado
     * @param {String} id ID del input
     * @param {String} label Etiqueta
     * @param {String} placeholder Placeholder del input
     * @param {String} tooltip Etiqueta del tooltip
     * @param {String} [key='id'] Clave única para el track by
     * @param {String} [display='nombre'] Propiedad que se va a mostrar
     * @param {Object} resource Servicio de tipo $resource sobre el que se ejecutará la operación de búsqueda
     * @param {String} [method='query'] Método del servicio (resource) que se utilizará en las búsquedas
     * @param {String} [queryParam='query'] Atributo en el que va a ir el texto de búsqueda al realizar la petición
     * @param {Object} parameters Parámetros que se enviarán en la petición. Útil para paginar resultados o obtenerlos ordenados
     * @param {Boolean} [required=false] Indica si el campo es obligatorio
     * @param {Boolean} [eliminable=false] Muestra el botón de eliminar junto al input
     * @param {Boolean} [restricted=false] Solo permite añadir elementos que están en el autocompletado. Si no está, se vaciará de manera automática
     * @param {Boolean} [disabled=false] Permite deshabilitar los inputs
     * @param {Number} [minLength=1] Número de caracteres requeridos para iniciar el autocompletado
     * @param {String} templateUrl URL a un archivo HTML, o nombre del ID del HTML.
     * En `app/templates/tesauro.templates.html` se pueden definir las que se utilicen más de una vez
     * @param {Array} excluidos Lista de elementos que se van a excluir.
     * La exclusión se hace en el lado cliente, por lo que el número de elementos mostrados puede no coincidir con la paginación.
     * Por lo tanto, si se utiliza este campo no se aconseja hacer uso de la paginación.
     * @param {Boolean} [simpleListForm=false] Booleano para indicar si el tesauro está dentro del componente ListForm, siempre y cuando no sea un objeto compuesto.
     * En este caso la manera de limpiar el objeto es diferente. Ejemplo en `agente-form.admin.html`.
     *
     * @requires app.component:labelForm
     */
    angular
        .module('app')
        .component('tesauro', {
            controller: Controller,
            controllerAs: 'ctrl',
            templateUrl: 'app/components/form/tesauro/tesauro.html',
            bindings: {
                ngModel: '=',
                id: '@',
                label: '@',
                placeholder: '@',
                tooltip: '@',
                key: '@',
                display: '@',
                resource: '<',
                method: '@',
                queryParam: '@',
                parameters: '<',
                required: '<',
                eliminable: '<',
                restricted: '<',
                disabled: '<',
                minLength: '@',
                templateUrl: '@',
                excluidos: '<',
                simpleListForm: '<'
            }
        });

    /* @ngInject */
    function Controller(JsonUtils) {
        var vm = this;

        // FIXME: Problema al guardar tradauctores dentro de las partes

        // Valores por defecto
        vm.key = vm.key || "id";
        vm.display = vm.display || "nombre";
        vm.method = vm.method || "query";
        vm.queryParam = vm.queryParam || "query";
        vm.minLength = vm.minLength || 1;

        //

        vm.clear = function () {
            if (!vm.simpleListForm) {
                vm.ngModel = null;
            } else {
                // En los casos en los que se trate de una lista de elementos se eliminan las propiedades del objeto para no perder la referencia.
                JsonUtils.cleanJson(vm.ngModel);
            }
        };

        vm.onBlur = function () {
            // Cuando se pierde el foco se comprueba si se ha vaciado el input.
            // Si es así y además es restricted, se eliminan el resto de datos.
            if ((!vm.ngModel || !vm.ngModel[vm.display]) && vm.restricted) {
                vm.clear();
            }
        };

        vm.setElement = function (item) {
            if (item.id) {
                angular.extend(vm.ngModel, item);
            }
        };

        vm.loadTags = function (query) {
            var params = !vm.parameters ? {} : vm.parameters;
            params[vm.queryParam] = query;
            return vm.resource[vm.method](params).$promise.then(function (data) {
                var lista;
                if (Array.isArray(data)) {
                    lista = data;
                } else {
                    lista = data.content;
                }
                return excluir(lista);
            });
        };

        // Excluimos de la lista de resultados los elementos que ya han sido seleccionados
        function excluir(lista) {
            if (vm.excluidos && vm.excluidos[0][vm.key]) {
                for (var i = 0; i < vm.excluidos.length; i++) {
                    for (var j = 0; j < lista.length; j++) {
                        if (vm.excluidos[i][vm.key] === lista[j][vm.key]) {
                            lista.splice(j, 1);
                            j = lista.length;
                        }
                    }
                }
            }
            return lista;
        }

    }

})();
