(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name app.service:Descargas
     * @description
     * Servicio con operaciones útiles para la generación y descarga de archivos.
     *
     * Requires: FileSaver
     *
     */
    angular
        .module('app')
        .factory('Descargas', Service);

    /* @ngInject */
    function Service($http) {

        return {
            /**
             * @ngdoc function
             * @name descargar
             * @methodOf app.service:Descargas
             * @description
             * Método que inicia la descarga de un archivo haciendo una llamada al servidor (GET)
             * y prepara la respuesta para su descarga,
             * poniendo nombre de archivo lo que se haya indicado en el atributo `filename` de la cabecera `Content-Disposition`.
             *
             * @param {String} url URL de descarga.
             * @param {Object} [parametros={}] Parámetros necesarios para la operación GET.
             */
            descargar: descargar
        };

        function descargar(url, parametros) {
            $http({
                method: 'GET',
                url: url,
                params: parametros,
                responseType: "arraybuffer"
            }).then(function (result) {
                var file = new Blob([result.data]);
                var contentDisposition = result.headers("Content-Disposition");
                var filename = contentDisposition.split(";")[1].trim().split('=')[1].replace(/"/g, '');
                saveAs(file, filename);
            });
        }
    }

})();
