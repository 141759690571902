(function () {
    'use strict';

    angular.module('app')
        .factory('Agente', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = 'agente';
        return ResourceHelper.getResource(entidad, {}, {
            filterTraductores: {
                method: 'GET',
                url: '/api/public/' + entidad + '/filterTraductores'
            },
            filterAutores: {
                method: 'GET',
                url: '/api/public/' + entidad + '/filterAutores'
            },
            getFicha: {
                method: 'GET',
                url: '/api/public/' + entidad + '/getFicha'
            }
        });
    }

})();
