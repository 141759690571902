(function () {
    'use strict';

    angular.module('app')
        .factory('Organismo', Service);

    /* @ngInject */
    function Service(ResourceHelper) {
        var entidad = 'organismo';
        return ResourceHelper.getResource(entidad, {}, {
            getFicha: {
                method: 'GET',
                url: '/api/public/' + entidad + '/getFicha'
            },
            findAllTesauro: {
                method: 'GET',
                url: '/api/public/' + entidad + '/findAllTesauro'
            }
        });
    }

})();
