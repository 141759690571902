(function () {
    'use strict';

    angular.module('app')
        .factory('DateUtilService', Service);

    /* @ngInject */
    function Service($filter) {
        return {
            getDateFromInteger: getDateFromInteger
        };

        function getDateFromInteger(day, month, year) {
            var format = null;
            if (year) {
                format = "yyyy";
                if (month) {
                    format = "MMMM yyyy";
                    month = month - 1;
                    if (day) {
                        format = "dd MMMM yyyy";
                    }
                }
                var date = new Date(year, month || 0, day || 1);
                return $filter('date')(date, format);
            }
            return null;
        }
    }
})();
