(function () {
    'use strict';

    angular
        .module('app')

        /* @ngInject */
        .constant('TRADUCCION_FORM_ADMIN', function (modal) {
            var resolve = "$resolve.";
            if (modal) {
                resolve = "ctrl.resolve."
            }
            return {
                component: '<traduccion-form-admin' +
                ' item="' + resolve + 'item"' +
                ' previous-params="' + resolve + 'previousParams"' +
                ' idiomas="' + resolve + 'idiomas"' +
                ' roles="' + resolve + 'roles"' +
                ' ciudades="' + resolve + 'ciudades"></traduccion-form-admin>',
                resolve: {
                    /* @ngInject */
                    item: function (Traduccion) {
                        return new Traduccion();
                    },
                    /* @ngInject */
                    idiomas: function (EnumService) {
                        return EnumService.get("idioma");
                    },
                    /* @ngInject */
                    roles: function (EnumService) {
                        return EnumService.get("rol-agente");
                    },
                    /* @ngInject */
                    ciudades: function (Ciudad) {
                        return Ciudad.findAll({sortProperty: 'nombre'}).$promise.then(function (data) {
                            return data.content;
                        });
                    }
                }
            }
        });
})();
