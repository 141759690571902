(function (angular) {
    'use strict';

    angular
        .module('app')
        .component('buscadorResultados', {
            templateUrl: 'app/public/traduccion/buscador-resultados.list.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                filter: '<',
                onReload: '&'
            }
        });

    /* @ngInject */
    function Controller($stateParams, $scope, NG_TABLE_DEFAULT_PARAMS, NgTableParams,
                        NgTableHelper, Traduccion) {
        var vm = this;

        vm.item = Traduccion;
        vm.function = 'buscar';
        vm.field = 'traducciones';
        vm.pagina = $stateParams.page ? $stateParams.page : 1;
        vm.elementosPorPagina = NG_TABLE_DEFAULT_PARAMS.size;
        vm.tableParams = new NgTableParams({
            count: vm.elementosPorPagina,
            page: vm.pagina,
            filter: vm.filter,
            sorting: {fechaAno: 'asc'}
        }, NgTableHelper.settings(vm));

        $scope.$watch(function () {
            return vm.ngTableResult;
        }, function (data) {
            vm.onReload({data: data});
        });

    }

})(angular);
