(function () {
    'use strict';

    angular
        .module('app')
        .component('buscador', {
            templateUrl: 'app/public/traduccion/buscador.html',
            controller: Controller,
            controllerAs: 'ctrl',
            bindings: {
                idiomas: '<',
                generos: '<',
                tipos: '<'
            }
        });

    /* @ngInject */
    function Controller($scope, $stateParams, $state, $location, Traduccion, Agente, Original, Organismo, JsonUtils) {
        var vm = this;

        function init() {
            vm.mostrarResultados = false;
            vm.filtroTabla = {};

            var buscar = false;

            // Se inicializa el filtro con los valores de los parámetros.
            // Hay que convertir los parámetros a integer o boolean cuando sea necesario.
            if (!JsonUtils.isEmpty($stateParams)) {
                vm.filtro = {
                    // Traducción
                    traduccionTitulo: $stateParams.traduccionTitulo,
                    traduccionIdioma: $stateParams.traduccionIdioma,
                    traduccionOrganismo: $stateParams.traduccionOrganismo,
                    traduccionAnoInicio: parseInt($stateParams.traduccionAnoInicio),
                    traduccionAnoFin: parseInt($stateParams.traduccionAnoFin),
                    // Traductor
                    traductorNombre: $stateParams.traductorNombre,
                    traductorGenero: $stateParams.traductorGenero,
                    traductorAutoriaOculta: $stateParams.traductorAutoriaOculta === "true" ? true : null,
                    // Original
                    originalTitulo: $stateParams.originalTitulo,
                    originalAutor: $stateParams.originalAutor,
                    originalGenero: $stateParams.originalGenero,
                    originalAutoriaOculta: $stateParams.originalAutoriaOculta === "true" ? true : null
                };
                vm.filtro.tipo = 'LIB';
                buscar = true;
            } else {
                vm.filtro = {tipo: 'LIB'};
            }

            // Inicializamos los tesauros con los valores de la URL
            if (vm.filtro.traduccionOrganismo) {
                vm.traduccionOrganismo = {nombre: vm.filtro.traduccionOrganismo};
            }
            if (vm.filtro.traductorNombre) {
                vm.traductorNombre = {nombre: vm.filtro.traductorNombre};
            }
            if (vm.filtro.originalTitulo) {
                vm.originalTitulo = {titulo: vm.filtro.originalTitulo};
            }
            if (vm.filtro.originalAutor) {
                vm.originalAutor = {nombre: vm.filtro.originalAutor};
            }

            if (buscar) {
                vm.search();
            }
        }

        vm.search = function () {
            // Obtenemos los valores de los tesauros y los añadimos al filtro
            if (vm.traduccionOrganismo) {
                vm.filtro.traduccionOrganismo = vm.traduccionOrganismo.nombre;
            }
            if (vm.traductorNombre) {
                vm.filtro.traductorNombre = vm.traductorNombre.nombre;
            }
            if (vm.originalTitulo) {
                vm.filtro.originalTitulo = vm.originalTitulo.titulo;
            }
            if (vm.originalAutor) {
                vm.filtro.originalAutor = vm.originalAutor.nombre;
            }

            angular.extend(vm.filtroTabla, JSON.parse(JSON.stringify(vm.filtro)));
            $state.go('.', vm.filtroTabla, {notify: false});
            vm.mostrarResultados = true;
        };

        vm.clean = function () {
            // Limpiamos tesauros
            vm.traduccionOrganismo = null;
            vm.traductorNombre = null;
            vm.originalTitulo = null;
            vm.originalAutor = null;

            vm.filtro = {tipo: vm.filtro.tipo};
            JsonUtils.cleanJson(vm.filtroTabla);
            vm.filtroTabla.tipo = vm.filtro.tipo;

            $state.go('.', {
                // Traducción
                traduccionTitulo: null,
                traduccionIdioma: null,
                traduccionOrganismo: null,
                traduccionAnoInicio: null,
                traduccionAnoFin: null,
                // Traductor
                traductorNombre: null,
                traductorGenero: null,
                traductorAutoriaOculta: null,
                // Original
                originalTitulo: null,
                originalAutor: null,
                originalGenero: null,
                originalAutoriaOculta: null
            }, {notify: false});
        };

        vm.changeTipo = function (tipo) {
            vm.filtro.tipo = tipo;
            vm.search();
        };

        // Servicios
        vm.traduccionService = Traduccion;
        vm.agenteService = Agente;
        vm.originalService = Original;
        vm.organismoService = Organismo;

        vm.traduccionesCount = {};
        vm.reload = function (data) {
            if (data) {
                vm.traduccionesCount = data.counts;
                if (data.tipo) {
                    vm.filtro.tipo = data.tipo;
                }
            }
        };

        init();
    }

})();
